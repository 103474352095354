import "isomorphic-fetch"
import { getUser } from "./auth"
const axios = require('axios').default

const SERVER = process.env.GATSBY_API_URL

// Detect if the app is running in a browser.
export const isBrowser = () => typeof window !== "undefined"

// get all projects
export const getUsers = async () => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(`${SERVER}/users`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
// get  project by id
export const getUserById = async id => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(`${SERVER}/users/${id}`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

// Reset user password
export const resetPassword = async email => {
  //try to get all projects
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
    const resp = await fetch(`${SERVER}/users/resetpassword/${email}`, options)
    console.log("resp", resp)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const updateUser = async user => {
  try {
    // Get the JWT token for the currently logged-in user.
    const token = getUser().jwt ? getUser().jwt : ""

    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user: user,
      }),
    }
    const resp = await fetch(`${SERVER}/users/${user._id}`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

export const updateSharedProjects = async (
  email,
  payloadId,
  ignoreEmailCheck
) => {
  try {
    // Get the JWT token for the currently logged-in user.
    const token = getUser().jwt ? getUser().jwt : ""

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        share: {
          email: email,
          metadataId: payloadId,
          ignoreEmailCheck,
        },
      }),
    }
    const resp = await fetch(`${SERVER}/users/share`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}
// get  project by id
export const getUserAddrs = async id => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(`${SERVER}/users/addr/${id}`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}


// get user tokens
export const getUserTokens = async id => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId : id
      }),
    }
    const resp = await fetch(`${SERVER}/token/user-tokens`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const exportUserToken = async ( tokenId, slpAddress) => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/export/user`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        tokenId,
        slpAddress
      }
    }
    const data = await axios(options)
    const txids = data.data //await data.json()
    return txids.result[0]
  } catch (e) {
    return false
  }
}
export const burnUserToken = async (tokenId) => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/burn/user`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        tokenId,
      }
    }
    const data = await axios(options)
    const txids = data.data //await data.json()
    return txids.result[0]
  } catch (e) {
    throw e
  }
}